<template>
    <div class="inner-wrapper" :class="data.playlistType" v-if="data">
        <slot name="header">
            <h2 v-if="data.displayTitle && data.displayTitle !== '' && data.displayTitle !== '#'" class="column-title">{{
                data.displayTitle }}</h2>
        </slot>
        <div class="playlist-wrapper">

            <VideoSliderBar :data="playlistItems" v-if="data.playlistType && data.playlistType == 'slider'" />

            <VideoGridCollection :data="playlistItems" v-if="data.playlistType && data.playlistType == 'overview'" />

            <!-- Show if is mobile overview -->
            <!-- <VideoSliderBar :data="playlistItems" v-if="data.playlistType && data.playlistType == 'overview'" /> -->

        </div>
        <slot name="footer">
            <div class="btn-wrapper" v-if="data.buttonUrl">
                <a :href="data.buttonUrl">{{ data.buttonLabel }}</a>
            </div>
        </slot>
    </div>
</template>

<script>
import { defineAsyncComponent } from "vue";

// @import component
const VideoSliderBar = defineAsyncComponent(() => import("@/components/PageLayoutBuilder/Partials/Video/VideoSliderBar"));
const VideoGridCollection = defineAsyncComponent(() => import("@/components/PageLayoutBuilder/Partials/Video/VideoGridCollection"));

export default {
    name: "PlayList",
    components: {
        VideoSliderBar,
        VideoGridCollection,
    },
    inject: ["isAuthenticated"],
    data() {
        return {
            data: null,
            playlistItems: [],
            showPlaylist: false,
        };
    },
    props: ["playlistExpanded"],
    created() {
        this.expandedInit();
    },
    mounted() { },
    methods: {
        expandedInit() {
            this.data = this.playlistExpanded;

            this.playlistItems = this.playlistExpanded.relatedContentsExpanded;

            if (this.playlistItems && this.playlistItems.length > 0) {

                let adjustedArray = [];

                this.playlistExpanded.relatedContentsExpanded.forEach(function (item) {

                    if (item.entityType === "VideoContent") {

                        if (item.videoContent) {
                            adjustedArray.push(item.videoContent);
                        }

                    } else if (item.entityType === "TemplateContent") {
                        adjustedArray.push(item.templateContent.templateContentFields);
                    }


                });

                this.playlistItems = adjustedArray;

                this.showPlaylist = true;

            }
        }
    },
};
</script>

<!-- <style lang="scss" scoped>
// @import styles
@import "./index.scss";
</style> -->
